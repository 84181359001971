<template>
  <div>
    <h3 class="page-title">ئالاھىدە ئەزا باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.prices" border style="width: 100%">
              <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
              <el-table-column prop="term.days" label="كۈن"></el-table-column>
              <el-table-column prop="price" label="باھا"></el-table-column>
              <el-table-column prop="status" width="120" label="ھالەت">
                <template slot-scope="scope">
                  <label v-show="scope.row.status==1">ئوچۇق</label>
                  <label v-show="scope.row.status==0">ئېتىك</label>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
        <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
        <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
        <el-table-column prop="order" label="تەرتىۋى"></el-table-column>
        <el-table-column prop="status" width="120" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status==1">ئوچۇق</label>
            <label v-show="scope.row.status==0">ئېتىك</label>
          </template>
        </el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="60%"
      top="5vh"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="ئەزا تۈرى" prop="vip_type">
            <el-select v-model="ruleForm.vip_type" class="rtl-right" placeholder="ئەزا تۈرى">
              <el-option label="بارلىق دەرىسلەرگە ئەزا ئېچىش" value="all_course"></el-option>
              <el-option label="ئوقۇتقۇچى تاللاپ ئەزا ئېچىش" value="choise_teacher"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ئوقۇتقۇچى" prop="teacher_id" v-if="ruleForm.vip_type == 'choise_teacher'">
            <el-select v-model="ruleForm.teacher_id" class="rtl-right" filterable placeholder="ئوقۇتقۇچى">
              <el-option
                v-for="item in teachersData"
                :key="item.id"
                :label="item.name_ug"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ئالاھىدە ئەزا (ئۇيغۇرچە)" prop="name" class="long-label" v-if="ruleForm.vip_type == 'all_course'">
            <el-input v-model="ruleForm.name_ug" placeholder="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="ئالاھىدە ئەزا (خەنزۇچە)" prop="name" class="long-label" v-if="ruleForm.vip_type == 'all_course'">
            <el-input v-model="ruleForm.name_zh" class="ltr-left" placeholder="会员（中文）"></el-input>
          </el-form-item>

          <!-- term-price -->
          <div class="main-box">
            <el-form-item label="مۇددەت باھاسى" prop="price">
              <el-row :gutter="15" v-for="(one,index) in termPriceList" :key="index" class="price-box">
                <el-col :span="9">
                  <el-select v-model="one.term_id" class="rtl-right" filterable placeholder="مۇددەت  تاللاڭ ">
                    <el-option
                      v-for="item in termsData"
                      :key="item.id"
                      :label="item.name_ug + '---'+ item.days + ' كۈن  ' "
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="9">
                  <el-input type="number" v-model="one.price" class="ltr-left" placeholder="باھاسى"></el-input>
                </el-col>
                <el-col :span="4" style="padding-top:10px;">
                  <el-switch v-model="one.status" active-color="#13ce66" inactive-color="#E4E7ED" active-value="1" inactive-value="0"></el-switch>
                </el-col>
                <el-col :span="2" class="last-col" v-if="ruleForm.vip_type == 'choise_teacher'">
                  <i class="el-icon-error" @click="removeItem(termPriceList,index)"></i>
                </el-col>
              </el-row>
            </el-form-item>
            <el-row v-if="ruleForm.vip_type == 'choise_teacher'">
              <el-col :span="10" :offset="7">
                <el-button icon="el-icon-circle-plus-outline" class="add-icon" @click="addItem(termPriceList)">يەنە بىرنى قوشۇش</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="تەرتىۋى" prop="order" class="long-label" style="margin-top:10px;">
                <el-input v-model="ruleForm.order" type="number" class="ltr-left" placeholder="تەرتىۋى"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="ھالەت" prop="status" class="radio-form">
                <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
                <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .last-col{
    text-align: center;
    position: relative;
    top: 8px;
    .el-icon-error{
      color: #ff5151;
      font-size: 28px;
      cursor: pointer;
    }
  }
  .price-box{
    overflow: hidden;
    [class*="el-col-"] {
      float: right;
    }
  }
  .add-icon{
    width: 100%; 
  }
  .main-box{
    border: 1px dotted #67C23A;
    margin-top: 10px;
    padding: 10px 0;
    border-radius: 5px;
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
      self.getTeachers();
      self.getTermsList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          vip_type:"",
          teacher_id:"",
          name_ug: "",
          name_zh: "",
          order: "",
          status: "1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{},
        teachersData:[],
        termsData:[],
        termPriceList:[
          {
            term_id:"",
            price:"",
            status:"1"
          }
        ],
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/vip").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      getTeachers(){
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
            self.teachersData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getTermsList(){
        self.$fetch("admin/term").then(response => {
          if (response.status == 200) {
           self.termsData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        if(row.teacher_id){
          self.ruleForm.vip_type = "choise_teacher";
          self.ruleForm.teacher_id = row.teacher_id;
        }else{
          self.ruleForm.vip_type = "all_course";
          self.ruleForm.name_ug = row.name_ug;
          self.ruleForm.name_zh = row.name_zh;
        }
        if(row.prices.length>0){
          var tmpArr = [];
          row.prices.forEach(function(one,index){
            var tmpObj = {};
            tmpObj.id = one.id;
            tmpObj.term_id = one.term_id;
            tmpObj.price = one.price;
            tmpObj.status = one.status + '';
            tmpArr.push(tmpObj);
          });
          self.termPriceList = tmpArr;
        }
        self.ruleForm.order = row.order;
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/vip/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          prices: self.termPriceList,
          order: self.ruleForm.order,
          status: self.ruleForm.status
        }
        if(self.ruleForm.vip_type == 'all_course'){
          myData.name_ug = self.ruleForm.name_ug;
          myData.name_zh = self.ruleForm.name_zh;
        }else{
          // myData.name_ug = "-";
          // myData.name_zh = "-";
          myData.teacher_id = self.ruleForm.teacher_id;
        }

        if(self.addDialog){
          self.$post('admin/vip',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/vip/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          teacher_id: "",
          name_ug: "",
          name_zh: "",
          order: "",
          status: "1"
        }
        self.termPriceList = [
          {
            term_id:"",
            price:"",
            status:"1"
          }
        ]
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      addItem(arr){
        if(arr.length < 5){
          var tmpData = {
            term_id:'',
            price:''
          };
          arr.push(tmpData);
        }else{
          self.$message({
            message: 'بەك كۆپ بوپكەتتىمۇ قانداق؟',
            type: "warning"
          });
        }
      },
      removeItem(arr,index){
        arr.splice(index, 1);
      },
    }
  };
</script>
